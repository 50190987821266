<template>
  <div>
    <v-data-table v-if="forms" :headers="headers" :items="forms">
      <template v-slot:[`item.created_at`]="{ item }">
        {{$dayjs(item.created_at).format('YYYY/MM/DD')}}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn icon color="primary" rounded 
            @click="$router.push({name: 'FormShow', params: {id: item.id}})"
            :disabled="$route.name == 'FormShow' && $route.params.id == item.id"
          ><v-icon>mdi-pencil</v-icon></v-btn>
        </div>
      </template>
    </v-data-table>    
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Api from '@/api/index'

export default {
  name: 'FormList',
  data: () => ({
    headers: [
      { text: 'Data', value: 'created_at', align: 'start'},
      { text: 'Nome', value: 'title' },
      { text: 'Ações', value: 'actions', sortable: false, align: 'end' },
    ],
    forms: [],
  }),
  methods: {
    getForms(){
      Api.Form.index().then(r=>r.data).then(d => this.forms = [...d])
    },
    ...mapActions({
      // function: 'Module/action'
    })
  },
  computed: {
    ...mapState({
      // data: state=> state.Module.state_var
    })
  },
  mounted () {
    this.getForms()
  },
}
</script>

<style scoped>
</style>